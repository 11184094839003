export default {
    state: {
        cf: {
            artworks: {
                taxCollectorId: 0,
                colors: [],
                types: [],

                nonRequired: [
                    "nonRequired",
                    "valueIndicatorsCounter",
                    "nonRequiredSub",
                    "validateSubFields",
                    "id",
                    "taxCollectorId",
                    "colors",
                    "types"
                ],
                validateSubFields: true,
                nonRequiredSub: [
                    "nonRequired",
                    "valueIndicatorsCounter",
                    "nonRequiredSub",
                    "validateSubFields",
                    "imageUrl",
                    "id",
                    "title",
                    "description",
                    "featuredImage"
                ],


                artistId: 0,
                price: 0,
                sizeWidth: 0,
                sizeHeight: 0,
                valueIndicators: [],
                gallery: [],
                collectorTrends: [],
                techniques: []
            },
            artist: {}
        }
    },
    mutations: {
        UPDATE_VALUE(state, payload) {
            state.cf[payload.type] = {
                ...state.cf[payload.type],
                ...payload.data
            }
        },
        COLLECT_CF_DATA(state) {
            return state;
        },
        COLLECT_FROM_CF(state) {
            return state;
        },
        BIND_VALUE_INDICATORS(state, payload) {
            if (payload.type === 'artist') {
                if (state.cf.artworks.valueIndicators[1]) {
                    state.cf.artworks.valueIndicators[1].title = payload.data.title;
                } else {
                    state.cf.artworks.valueIndicators.push({
                        id: 0,
                        imageUrl: '',
                        title: payload.data.title,
                        textList: payload.data.textList,
                    })
                }

            } else {
                if (state.cf.artworks.valueIndicators[0] && state.cf.artworks.valueIndicators.length > 1) {
                    state.cf.artworks.valueIndicators[0].title = payload.data.title;
                } else {
                    state.cf.artworks.valueIndicators.push({
                        id: 1,
                        imageUrl: '',
                        title: payload.data.title,
                        textList: payload.data.textList,
                    })
                }

            }
        },
        RESET_IMG_SELECTOR(state) {
            return state;
        },
        RESET_COLLECT_FROM_CF(state) {
            state.cf.artworks.valueIndicators = [];
        }
    },
    actions: {
        updateValue({ commit }, payload) {
            commit("UPDATE_VALUE", payload)
        },
        collectData({ commit }) {
            commit("COLLECT_CF_DATA");
        },
        bindValueIndicators({ commit }, value) {
            commit("BIND_VALUE_INDICATORS", value)
        },
        resetImgSelector({ commit }) {
            commit("RESET_IMG_SELECTOR")
        },
        collectFromCF({ commit }) {
            commit("COLLECT_FROM_CF")
        },
        resetCollectFromCf({ commit }) {
            commit("RESET_COLLECT_FROM_CF")
        }
    }
}