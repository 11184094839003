<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">Dashboard</div>
  </div>
  <div class="home">
    <h1>Welcome to</h1>
    <h3>Content management system</h3>
  </div>
</template>

<script>
export default {
  name: "Home",
  methods: {
    onClickButton() {
      this.$router.push({
        name: "Posts",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  text-align: center;
  .dashboard-logo {
    margin-bottom: 50px;
  }
}
</style>
