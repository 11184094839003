import axios from "axios";
import { ElNotification } from "element-plus";
import store from '../store'
import { tagsConvertor } from '../helpers/tagsConvertor'

export const createPost = async (postType, postData, customTaxs) => {
    let data = store.state.cf.cf[postType];
    if (postData && data) {
        let formdata = new FormData();
        let headers = {
            headers: {
                Authorization: "Bearer " + store.state.user.user,
            },
        }
        formdata.append("title", postData.title);
        formdata.append("slug", postData.slug);
        formdata.append("artist_id", data.artistId);
        formdata.append("price", data.price);
        formdata.append("size_width", data.sizeWidth);
        formdata.append("size_height", data.sizeHeight);
        formdata.append("featured_image_id", postData.featuredImage);
        formdata.append("image_id", postData.featuredImage);

        data.gallery.map((galleryId) => {
            formdata.append("gallery[]", galleryId);
        });
        formdata.append(
            "value_indicators",
            JSON.stringify(data.valueIndicators)
        );
        formdata.append(
            "overview",
            JSON.stringify(postData.content)
        );
        data.techniques.map(techniques => {
            formdata.append(
                "technique_ids[]",
                techniques.techniqueId
            );
        })
        customTaxs.map(tax => {
            if (tax.name === 'collector-items') {
                formdata.append(
                    "collector_item_id",
                    tax.taxs
                );
            } else {
                tax.taxs.map(t => {
                    formdata.append(
                        `${tax.name}[]`,
                        t
                    );
                })
            }
        })
        let tags = await tagsConvertor(postData.choosenTags, postData.primaryTagId)
        if (tags.length > 0) {
            formdata.append("tags", JSON.stringify(tags));
        }
        const r = await axios.post(`${process.env.VUE_APP_SHARED_API}api/${postType}`, formdata,
            headers
        )
        return r;
    } else {
        ElNotification({
            title: "Oops!",
            message: "Something went wrong, please try later",
            type: "warning",
        });
    }
}
