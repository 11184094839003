import { createStore } from 'vuex'
import user from './modules/user'
import items from './modules/items'
import media from './modules/media'
import cf from './modules/cf'

export default createStore({
  modules: {
    user,
    items,
    media,
    cf
  }
})
