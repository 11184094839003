<template>
  <h4>Tags</h4>
  <div class="row">
    <div class="col-4">
      <form v-if="!updateTag" class="mt-4" @submit.prevent="createTag">
        <label for="size-w"
          >Tag name <span class="required-field">*</span></label
        >
        <el-input
          v-model="name"
          type="text"
          required
          id="name"
          placeholder="Tag name"
          class="mt-2"
        ></el-input>
        <el-button class="button_style_1 mt-4" @click="createTag">
          Add New Tag
        </el-button>
      </form>
      <form v-else class="mt-4" @submit.prevent="updateTagF">
        <label for="size-w"
          >Tag name <span class="required-field">*</span></label
        >
        <el-input
          v-model="name"
          :value="tagToUpdate.name"
          type="text"
          required
          id="name"
          placeholder="Tag name"
          class="mt-2"
        ></el-input>
        <el-button class="button_style_1 mt-4" @click="updateTagF">
          Update Tag
        </el-button>
      </form>
    </div>
    <div class="col-8 mt-2">
      <el-table :data="tags" stripe style="width: 100%">
        <el-table-column label="Tag Name">
          <template #default="scope"
            >{{ tags[scope.$index].name }}
            <div class="danger-area">
              <p
                @click="
                  () => {
                    this.tagToUpdate = tags[scope.$index];
                    this.updateTag = true;
                    this.name = tags[scope.$index].name;
                  }
                "
              >
                Edit
              </p>
              <p class="delete" @click="() => deleteTag(tags[scope.$index].id)">
                Delete
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="havePagination">
        <el-pagination
          layout="prev, pager, next"
          :total="totalNum"
          :page-count="Math.ceil(totalNum / 20)"
          :current-page="pageNo"
          @current-change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  ElButton,
  ElInput,
  ElNotification,
  ElTable,
  ElTableColumn,
  ElMessage,
  ElMessageBox,
} from "element-plus";
export default {
  components: {
    ElButton,
    ElInput,
    ElTable,
    ElTableColumn,
  },
  data() {
    return {
      name: "",
      tags: [],
      havePagination: false,
      totalNum: 0,
      pageNo: 1,
      updateTag: false,
      tagToUpdate: {},
    };
  },
  methods: {
    createTag() {
      if (this.name === "") {
        ElNotification({
          title: "Warning!",
          message: "Mandatory fields must be filled in!",
          type: "warning",
        });
      } else {
        axios
          .post(
            `${process.env.VUE_APP_SHARED_API}api/tags`,
            {
              name: this.name,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$cookies.get("bearerToken"),
              },
            }
          )
          .then((res) => {
            if (res.data.id) {
              this.name = "";
              this.tags.push({
                id: res.data.id,
                name: res.data.name,
              });
            } else {
              ElNotification({
                title: "Warning!",
                message: "Something went wrong, please try later!",
                type: "warning",
              });
            }
          });
      }
    },
    updateTagF() {
      axios
        .put(
          `${process.env.VUE_APP_SHARED_API}api/tags/${this.tagToUpdate.id}`,
          {
            name: this.name,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$cookies.get("bearerToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.id) {
            this.name = "";
            let toUpdateByIndex = this.tags.findIndex(
              (x) => x.id == res.data.id
            );
            this.tags[toUpdateByIndex].name = res.data.name;
            this.updateTag = false;
          } else {
            ElNotification({
              title: "Warning!",
              message: "Something went wrong, please try later!",
              type: "warning",
            });
          }
        });
    },
    async fetchTags(pageNo) {
      const loader = this.$loading.show();
      return axios
        .get(
          `${process.env.VUE_APP_SHARED_API}api/tags?per_page=20${
            pageNo ? `&page=${pageNo}` : ``
          }`,
          {
            headers: {
              Authorization: "Bearer " + this.$cookies.get("bearerToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.current_page) {
            this.tags = res.data.data;
            loader.hide();
          }
          if (res.data.last_page > 1) {
            this.havePagination = true;
            this.totalNum = res.data.total;
          }
        });
    },
    pageChange(pageNo) {
      this.pageNo = pageNo;
      this.fetchTags(pageNo);
    },
    deleteTag(id) {
      ElMessageBox.confirm("Are you sure?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        axios
          .delete(`${process.env.VUE_APP_SHARED_API}api/tags/${id}`, {
            headers: {
              Authorization: "Bearer " + this.$cookies.get("bearerToken"),
            },
          })
          .then((res) => {
            let deleteByIdnex = this.tags.findIndex((x) => x.id == id);
            if (res.status === 200) {
              ElMessage({
                type: "success",
                message: "Delete completed",
              });
              this.tags.splice(deleteByIdnex, 1);
            }
          });
      });
    },
  },
  mounted() {
    this.fetchTags();
  },
  watch: {
    name: function (newVal, oldVal) {
      if (!newVal && this.updateTag === true) this.updateTag = true; // find way how to reset update mode?
    },
  },
};
</script>

<style lang="scss" scoped>
.required-field {
  color: red;
  font-weight: bold;
}
.danger-area {
  width: 100%;
  display: flex;
  opacity: 0;
  transition: all 0.3s;
}
tr:hover .danger-area {
  transition: all 0.3s;
  opacity: 1;
}
.danger-area p {
  margin: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  color: #2271b1;
  margin-right: 10px;
  line-height: 10px;
}
.danger-area p.delete {
  color: #b32d2e;
}
</style>