<template>
  <div class="row mb-2">
    <div class="col" v-if="artistList.length > 0">
      <label for="artist">Artist <span class="required-field">*</span></label>
      <el-select
        v-model="artistId"
        class="m-2"
        placeholder="Please select one"
        size="large"
        filterable
      >
        <el-option
          v-for="item in artistList"
          :key="item.id"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="col" v-else>
      <p>{{ serverMessage.artist }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label for="price">Price <span class="required-field">*</span></label>
      <el-input
        @keypress="isNumber($event)"
        v-model="price"
        type="text"
        min="0"
        required
        id="price"
        placeholder="Price"
        class="mb-2"
      ></el-input>
    </div>
    <div class="col">
      <label for="size-w"
        >Size Width <span class="required-field">*</span></label
      >
      <el-input
        v-model="sizeWidth"
        @keypress="isNumber($event)"
        required
        id="size-w"
        placeholder="Size Width"
        class="mb-2"
        type="text"
        min="0"
      ></el-input>
    </div>
    <div class="col">
      <label for="size-h"
        >Size Height <span class="required-field">*</span></label
      >
      <el-input
        v-model="sizeHeight"
        @keypress="isNumber($event)"
        required
        id="size-h"
        placeholder="Size Height"
        class="mb-2"
        type="text"
        min="0"
      ></el-input>
    </div>
  </div>
</template>

<script>
import { fetchConfig } from "../../helpers/fetchConfig";
import { ElSelect, ElOption, ElInput } from "element-plus";

export default {
  components: {
    ElSelect,
    ElOption,
    ElInput,
  },
  props: {
    postType: {
      type: String,
    },
    serverData: {
      require: false,
      type: Object,
    },
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      artistList: [],
      artistListRaw: [],
      techniquesList: [],
      techniquesListRaw: [],
      artistId: "Please select", // 0
      serverMessage: {
        artist: "Loading...",
        techniques: "Loading...",
      },
      price: 0,
      sizeWidth: 0,
      sizeHeight: 0,
    };
  },
  mounted() {
    const loader = this.$loading.show();
    fetchConfig("artists", this.$cookies.get("bearerToken"))
      .then(({ data: { data } }) => {
        if (data.length > 0) {
          this.artistListRaw = data;
          data.map((artist) => {
            this.artistList.push({
              id: artist.id,
              label: artist.name,
              value: artist.id,
            });
          });
        } else {
          this.serverMessage.artist = "Ops! Please try later!";
        }
      })
      .catch((error) => (this.serverMessage.artist = "Ops! Please try later!"));
    loader.hide();
  },
  watch: {
    artistId: function (newVal, oldVal) {
      if (this.$route.query.mode !== "edit") {
        let val = this.artistListRaw.find((x) => x.id === newVal);
        this.$store.dispatch("bindValueIndicators", {
          type: "artist",
          data: {
            title: val.value_indicator.name,
            textList: [
              {
                id: 0,
                text: val.value_indicator.content,
              },
            ],
          },
        });
      }
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$store.dispatch("updateValue", {
          type: this.postType,
          data: {
            artistId: this.artistId === "Please select" ? 0 : this.artistId,
            price: this.price,
            sizeWidth: this.sizeWidth,
            sizeHeight: this.sizeHeight,
          },
        });
      }
      if (mutation.type === "COLLECT_FROM_CF") {
        this.price = state.cf.cf.artworks.price;
        this.sizeWidth = state.cf.cf.artworks.sizeWidth;
        this.sizeHeight = state.cf.cf.artworks.sizeHeight;
        this.artistId = state.cf.cf.artworks.artistId;
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.required-field {
  color: red;
  font-weight: bold;
}
</style>