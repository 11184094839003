<template>
  <div class="main-menu">
    <div class="navigation-menu">
      <ul>
        <li>
          <router-link
            :to="{
              name: 'Dashboard',
            }"
          >
            <font-awesome-icon
              :icon="['fas', 'tachometer-alt']"
              class="nav_menu_icons"
            />
            Dashboard
          </router-link>
        </li>
        <li>
          <el-dropdown trigger="click">
            <a>
              <font-awesome-icon
                :icon="['fas', 'copy']"
                class="nav_menu_icons"
              />
              Pages<i class="el-icon-arrow-down el-icon--right"></i>
            </a>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Pages',
                    }"
                  >
                    All Pages
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'New Item',
                      params: {
                        type: 'page',
                      },
                    }"
                  >
                    Add new page
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Pages trash',
                    }"
                  >
                    Trash
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li>
          <router-link
            :to="{
              name: 'Users',
            }"
          >
            <font-awesome-icon
              :icon="['fas', 'users']"
              class="nav_menu_icons"
            />
            Users
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: 'Menu',
            }"
          >
            <font-awesome-icon
              :icon="['fab', 'elementor']"
              class="nav_menu_icons"
            />
            Menus
          </router-link>
        </li>
        <li>
          <el-dropdown trigger="click">
            <a>
              <font-awesome-icon
                :icon="['fas', 'paint-brush']"
                class="nav_menu_icons"
              />
              Artworks<i class="el-icon-arrow-down el-icon--right"></i>
            </a>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Artworks',
                    }"
                  >
                    All Artworks
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'New Item',
                      params: {
                        type: 'artworks',
                      },
                      query: {
                        v: '2',
                        mode: 'new',
                        singular: 'artwork',
                      },
                    }"
                  >
                    Add new artwork
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Tags',
                    }"
                  >
                    Tags
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'CustomCat',
                      params: {
                        type: 'collector-items',
                      },
                    }"
                  >
                    Collecotr Items
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'CustomCat',
                      params: {
                        type: 'colors',
                      },
                    }"
                  >
                    Colors
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'CustomCat',
                      params: {
                        type: 'types',
                      },
                    }"
                  >
                    Types
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
      </ul>
      <div class="logout_button">
        <el-button type="warning" plain @click="logOutUser">Log out</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavigationMenu",
  methods: {
    logOutUser() {
      this.$cookies.remove("bearerToken");
      this.$store.dispatch("logOutUser");
      this.$router.push("/login");
    },
  },
};
</script>
<style>
.nav_menu_icons {
  margin-right: 5px;
  min-width: 30px;
}
</style>
