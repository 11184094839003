<template>
  <div class="row">
    <div class="col">
      <label for="value"
        >Collector Trends <span class="required-field">*</span></label
      >
      <div>
        <div v-if="collectorTrends.length > 0">
          <div v-for="(item, index) in collectorTrends" :key="index">
            <div class="row-wrapper p-2">
              <label for="value-indicators-icon">Featured Image </label>
              <ImageSelectorInput
                v-model="item.featuredImage"
                :multiple="false"
                :featuredImageSlug="'empty'"
                :gallery="''"
              ></ImageSelectorInput>

              <label class="mt-2" for="title">Title </label>
              <el-input
                v-model="item.title"
                required
                id="title"
                placeholder="Title"
                class="mb-2"
              ></el-input>

              <label class="mt-2" for="title"
                >Price <span class="required-field">*</span></label
              >
              <el-input
                @keypress="isNumber($event)"
                v-model="item.price"
                required
                id="title"
                placeholder="Title"
                class="mb-2"
                type="text"
              ></el-input>

              <label class="mt-2" for="title"
                >Date <span class="required-field">*</span></label
              >
              <br />
              <el-date-picker
                v-model="item.date"
                type="date"
                placeholder="Pick a date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
              />
              <br />

              <label class="mt-2" for="title">Description </label>
              <el-input
                v-model="item.description"
                :rows="2"
                type="textarea"
                placeholder="Please input"
              />

              <div
                class="delete-row"
                @click="
                  () => {
                    this.collectorTrends.splice(index, 1);
                  }
                "
              >
                <svg
                  class="svg-inline--fa fa-trash-alt fa-w-14"
                  data-v-b4ae8fbc=""
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="trash-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <el-button
          class="button_style_1 mt-2"
          @click="
            () => {
              this.collectorTrends.push({
                id: this.collectorTrendsCounter++,
                featuredImage: 0,
                title: '',
                price: 0,
                date: '',
                description: '',
              });
            }
          "
        >
          Add New Row
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ElButton, ElInput, ElDatePicker } from "element-plus";
import ImageSelectorInput from "../Tiptap/ImageSelectorInput.vue";
export default {
  props: {
    postType: {
      type: String,
    },
    serverData: {
      require: false,
    },
  },
  components: {
    ElButton,
    ElInput,
    ElDatePicker,
    ImageSelectorInput,
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      collectorTrends: [],
      collectorTrendsCounter: 0,
    };
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$store.dispatch("updateValue", {
          type: this.postType,
          data: {
            collectorTrends: this.collectorTrends,
          },
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.delete-row > svg {
  color: white;
  fill: white;
}
.required-field {
  color: red;
  font-weight: bold;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
</style>