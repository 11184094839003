<template>
  <h3 class="single_post_box__h3">
    {{ tax.title.replace("-", " ") }}
    <span class="required-field">*</span>
  </h3>
  <div v-if="tax && tax.data.length > 0">
    <ul class="tags-list">
      <el-input
        v-model="searchQuery"
        placeholder="Search item"
        class="mb-4 p-sticky"
        :disabled="waitOnServer === true"
      ></el-input>
      <button v-if="waitOnServer === true" class="search-btn"></button>
      <div v-if="tax.title == 'collector-items'">
        <p v-if="waitOnServer === true">{{ serverSearch }}</p>
        <el-radio-group v-model="choosenTax">
          <li class="tag-list-item" v-for="t in filteredList" :key="t.id">
            <el-radio size="large" :label="t.id"
              >{{ t.name || t.title }}
            </el-radio>
          </li>
        </el-radio-group>
      </div>
      <div v-else>
        <p v-if="waitOnServer === true">{{ serverSearch }}</p>
        <el-checkbox-group v-model="choosenTax">
          <li class="tag-list-item" v-for="b in filteredList" :key="b.id">
            <el-checkbox size="large" :label="b.id"
              >{{ b.title || b.name }}
            </el-checkbox>
          </li>
        </el-checkbox-group>
      </div>
    </ul>
  </div>
  <p v-else>No data...</p>
</template>

<script>
import {
  ElRadio,
  ElRadioGroup,
  ElCheckbox,
  ElCheckboxGroup,
} from "element-plus";

import { searchCategory } from "../../../services/searchCategories";

export default {
  components: {
    ElRadio,
    ElRadioGroup,
    ElCheckbox,
    ElCheckboxGroup,
  },
  props: {
    tax: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: "",
      choosenTax: [],
      waitOnServer: false,
      serverSearch: "No data! Please search on the server.",
    };
  },
  methods: {
    findOnServer(query) {
      console.log(query);
    },
  },
  computed: {
    filteredList() {
      return this.tax.data.filter((t) => {
        if (t.title !== undefined) {
          return t.title.toLowerCase().includes(this.searchQuery.toLowerCase());
        } else {
          return t.name.toLowerCase().includes(this.searchQuery.toLowerCase());
        }
      });
    },
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$emit("collectTaxs", {
          name: this.tax.title,
          taxs: this.choosenTax,
        });
      }
      if (mutation.type === "COLLECT_FROM_CF") {
        if (this.tax.title === "collector-items") {
          this.choosenTax = state.cf.cf.artworks.taxCollectorId;
        } else {
          this.choosenTax = state.cf.cf.artworks[this.tax.title];
        }
      }
    });
  },
  watch: {
    filteredList: {
      deep: true,
      handler: function (newVal) {
        // if (newVal.length < 1) {
        //   this.waitOnServer = true;
        //   searchCategory(
        //     this.searchQuery,
        //     this.$cookies.get("bearerToken"),
        //     this.tax.title
        //   ).then((res) => {});
        // }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-title-section-content {
  p {
    margin-bottom: 0;
    text-transform: capitalize;
  }
}

.single_post_main_box {
  padding: 25px;
  background-color: #f2f2f2;
  border-radius: 5px;
  height: 100%;
}

.single_post_box {
  background-color: #f2f2f2;
  padding: 25px;
  margin-bottom: 25px;
  border-radius: 5px;

  .single_post_box__h3 {
    font-size: 20px;
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  .featured_image_single {
    max-width: 400px;
    margin: 0 auto;
  }
}

.category_checkbox:active,
.category_checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.categories_box {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  height: 150px;
  overflow-y: scroll;
}

.single_category__div {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;

  .category_checkbox {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
  }

  label {
    font-size: 14px;
    cursor: pointer;
  }

  .category_checkbox:checked {
    background-color: #e9ecee;
    border: 1px solid #42b983;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }

  .category_checkbox:checked:after {
    content: "\2714";
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: #42b983;
  }
}

.buttons_section {
  text-align: right;
}
.tip_tap_editor {
  max-height: 650px;
  height: 100%;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.delete-row > svg {
  color: white;
  fill: white;
}
.required-field {
  color: red;
  font-weight: bold;
}
.tags-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
  max-height: 150px;
  height: 100%;
  overflow-y: scroll;
}
.tags-list li {
  padding-left: 10px;
}
.tag-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}
.tag-list-item svg {
  width: 18px;
  height: 18px;
  margin-left: 30px;
  cursor: pointer;
}
.active-star svg {
  color: #dddd00;
}
.p-sticky {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
}
.search-btn {
  max-width: 32px;
  max-height: 32px;
  position: aboslute;
  top: 10px;
  right: 5px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid white;
}
</style>