<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">
      {{ props.menuName }}
    </div>
    <div class="new_menu_button_sec" v-if="props.menuName === 'Categories'">
      <router-link
        :to="{
          name: 'New Item',
          params: {
            type: 'category',
          },
        }"
      >
        <el-button> Add new category </el-button>
      </router-link>
    </div>
  </div>
  <div class="languages_switcher" v-if="languages.length !== 0">
    <p
      v-for="(language, index) in languages"
      :key="index"
      class="languages_switcher__p"
    >
      <router-link :to="/posts/ + language.name">
        {{ language.name }}
      </router-link>
    </p>
  </div>
  <div class="items">
    <div v-if="$route.name === 'Tags'">
      <tags />
    </div>
    <div v-else-if="$route.name === 'CustomCat'">
      <custom-tax />
    </div>
    <div v-else class="row items_form_title">
      <div
        v-for="(value, index) in propsTitle"
        :key="index"
        class="items_form_title__columns"
        :class="'col-md-' + value.columns"
      >
        {{ value.name }}
      </div>
    </div>
    <div v-if="Object.keys(props).length">
      <div class="row item_list" v-if="Object.keys(props).length !== 0">
        <div
          class="row single_row_item"
          v-for="(item, index) in $store.state.items.items"
          :key="index"
        >
          <div
            v-if="props.title.show === true"
            class="item_title"
            :class="'col-md-' + props.title.columns"
          >
            <div v-if="item.post_content">
              <div v-for="(title, index) in item.post_content" :key="index">
                <h5>{{ title.title }}</h5>
                <p class="single_row_item__p">
                  <router-link
                    :to="{
                      name: 'edit_item',
                      params: {
                        type: 'post',
                        id: title.post_id,
                      },
                    }"
                    >Edit post
                  </router-link>
                </p>
                <p
                  class="single_row_item__p"
                  @click="deleteItem(type, title.post_id)"
                >
                  Delete post
                </p>
              </div>
            </div>
            <div v-else-if="item.page_content">
              <div v-for="(title, index) in item.page_content" :key="index">
                <h5>{{ title.title }}</h5>
                <p class="single_row_item__p">
                  <router-link
                    :to="{
                      name: 'edit_item',
                      params: {
                        type: 'page',
                        id: title.page_id,
                      },
                    }"
                    >Edit page
                  </router-link>
                </p>
                <p
                  class="single_row_item__p"
                  @click="deleteItem('pages', title.id)"
                >
                  Delete page
                </p>
              </div>
            </div>
            <div v-else-if="item.category_content">
              <div
                v-for="(category, index) in item.category_content"
                :key="index"
              >
                <h5>{{ category.name }}</h5>
                <p class="single_row_item__p">
                  <router-link
                    :to="{
                      name: 'edit_item',
                      params: {
                        type: 'category',
                        id: category.category_id,
                      },
                    }"
                    >Edit category
                  </router-link>
                </p>
                <p
                  class="single_row_item__p"
                  @click="deleteItem('category', category.category_id)"
                >
                  Delete category
                </p>
              </div>
            </div>
            <div v-else>
              <div v-if="type === 'pages/trash'">
                <h5>{{ item.title }}</h5>
                <p
                  class="single_row_item__p"
                  @click="restoreItem('pages', item.page_id)"
                >
                  Restore page
                </p>
                <p
                  class="single_row_item__p"
                  @click="permanentDeleteItem('pages', item.page_id)"
                >
                  Delete permanent
                </p>
              </div>
              <div v-else>
                <h5>{{ item.title }}</h5>
                <p
                  class="single_row_item__p"
                  @click="restoreItem('posts', item.post_id)"
                >
                  Restore post
                </p>
                <p
                  class="single_row_item__p"
                  @click="permanentDeleteItem('posts', item.post_id)"
                >
                  Delete permanent
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="props.categories.show === true"
            class="item_title"
            :class="'col-md-' + props.categories.columns"
          >
            <div v-if="item.categories">
              <div v-if="item.categories.length !== 0">
                <el-button
                  @click="openCategoryDialog(item.categories)"
                  class="button_style_1"
                >
                  VIEW CATEGORIES
                </el-button>
              </div>
            </div>
          </div>
          <div
            v-if="props.author.show === true"
            class="item_title"
            :class="'col-md-' + props.author.columns"
          >
            <div v-if="item.author">
              {{ item.author.name }}
            </div>
          </div>
          <div
            v-if="props.created_at.show === true"
            class="item_title"
            :class="'col-md-' + props.created_at.columns"
          >
            {{ $moment(item.created_at).format("DD MMM YYYY") }}
          </div>
          <div
            v-if="props.status.show === true"
            class="item_title"
            :class="'col-md-' + props.status.columns"
          >
            <div v-if="item.post_content">
              <div v-for="(title, index) in item.post_content" :key="index">
                {{ title.status.name }}
              </div>
            </div>
            <div v-else-if="item.page_content">
              <div v-for="(page, index) in item.page_content" :key="index">
                {{ page.status.name }}
              </div>
            </div>
          </div>

          <div
            v-if="props.languages.show === true"
            class="item_title"
            :class="'col-md-' + props.languages.columns"
          >
            <div class="language_flags">
              <img
                v-for="(img, index) in languages"
                :src="img.imagePath"
                :alt="img.name"
                :key="index"
              />
            </div>
            <div class="add_or_edit_item_language">
              <div
                v-for="(language, index) in item.languages"
                :key="index"
                class="add_or_edit_item_language"
              >
                <div v-if="language">
                  <router-link
                    v-bind:to="'/' + index + '/' + type + '/' + item.id"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'pencil-alt']"
                      class="language_icon language_icon__edit"
                    />
                  </router-link>
                </div>
                <div v-else>
                  <router-link
                    v-bind:to="'/' + index + '/' + type + '/' + item.id"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      class="language_icon language_icon__add"
                    />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="props.deleted_at.show === true"
            class="item_title"
            :class="'col-md-' + props.deleted_at.columns"
          >
            {{ $moment(item.deleted_at).format("DD MMM YYYY") }}
          </div>
        </div>
      </div>
    </div>
    <Pagination
      v-if="type && $route.name !== 'Tags' && $route.name !== 'CustomCat'"
      :type="type"
      :current_page="current_page"
    ></Pagination>
  </div>
  <el-dialog title="Categories" v-model="dialogVisible" width="30%">
    <div v-for="(category, index) in categories" :key="index">
      <p class="dialog_category_p">
        {{ category }}
      </p>
    </div>
  </el-dialog>
</template>
<script>
import {
  categoriesObject,
  pagesObject,
  postsObject,
  pagesTrashObject,
  postsTrashObject,
} from "../../helpers/config";
import { allLanguages } from "../../helpers/languages";
import Pagination from "@/views/Pagination";

import Tags from "./Tags.vue";
import CustomTax from "./CustomTax.vue";

export default {
  name: "ItemsList",
  components: {
    Pagination,
    Tags,
    CustomTax,
  },
  data() {
    return {
      type: null,
      props: {},
      languages: [],
      current_page: 1,
      categories: [],
      itemLanguages: [],
      dialogVisible: false,
      singularType: "",
      propsTitle: [],
    };
  },
  props: ["singleObj"],
  methods: {
    deleteItem(type, id) {
      const loader = this.$loading.show();
      this.$store
        .dispatch("deleteItem", {
          type: type,
          id: id,
        })
        .then(() => {
          loader.hide();
        });
    },
    restoreItem(type, id) {
      const loader = this.$loading.show();
      this.$store
        .dispatch("restoreItem", {
          type: type,
          id: id,
        })
        .then(() => {
          loader.hide();
        });
    },
    permanentDeleteItem(type, id) {
      const loader = this.$loading.show();
      this.$store
        .dispatch("permanentDelete", {
          type: type,
          id: id,
        })
        .then(() => {
          loader.hide();
        });
    },
    openCategoryDialog(categoryName) {
      this.categories = [];
      categoryName.map((category) => {
        category.category_content.map((singleCat) => {
          this.categories.push(singleCat.name);
        });
      });
      this.dialogVisible = true;
    },
  },
  mounted() {
    if (this.$route.name !== "Tags" && this.$route.name !== "CustomCat") {
      const loader = this.$loading.show();
      this.type = this.$route.fullPath.substring(1);
      if (this.type === "categories") {
        this.type = "category";
      }
      if (this.type !== null) {
        this.$store.dispatch("fetchData", { type: this.type }).then(() => {
          if (process.env.VUE_APP_MULTI_LANGUAGE === "true") {
            this.languages = allLanguages();
            if (this.$store.state.items.items) {
              const posts = this.$store.state.items.items.map((item) => {
                const translatedOn = item.post_content.map((translation) => {
                  return translation.lang;
                });
                item.languages = {};
                Object.values(allLanguages()).map((lang) => {
                  item.languages[lang.name] = translatedOn.includes(lang.name);
                });
                return item;
              });
              this.$store.state.items.items = posts;
            }
          }
          loader.hide();
        });
      } else {
        this.$store
          .dispatch("fetchData", { type: this.$route.fullPath.substring(1) })
          .then(() => {
            if (process.env.VUE_APP_MULTI_LANGUAGE === "true") {
              this.languages = allLanguages();
              if (this.$store.state.items.items) {
                const posts = this.$store.state.items.items.map((item) => {
                  const translatedOn = item.post_content.map((translation) => {
                    return translation.lang;
                  });
                  item.languages = {};
                  Object.values(allLanguages()).map((lang) => {
                    item.languages[lang.name] = translatedOn.includes(
                      lang.name
                    );
                  });
                  return item;
                });
                this.$store.state.items.items = posts;
              }
            }
            loader.hide();
          });
      }
      this.type = this.$route.fullPath.substring(1);
      if (this.type === "pages") {
        this.singularType = "page";
      } else if (this.type === "posts") {
        this.singularType = "post";
      } else if (this.type === "categories") {
        this.singularType = "category";
      } else if (this.type === "artworks") {
        this.singularType = "artwork";
      }
      let propsObj = null;
      switch (this.type) {
        case "posts":
          propsObj = postsObject();
          break;
        case "pages":
          propsObj = pagesObject();
          break;
        case "categories":
          propsObj = categoriesObject();
          break;
        case "posts/trash":
          propsObj = postsTrashObject();
          break;
        case "pages/trash":
          propsObj = pagesTrashObject();
          break;
        case "artworks":
          propsObj = postsObject();
          break;
        default:
          propsObj = null;
      }
      Object.assign(this.props, propsObj);
      for (const key in propsObj) {
        if (propsObj[key].show === true) {
          var singleObj = {};
          singleObj = propsObj[key];
          this.propsTitle.push(singleObj);
        }
      }
    }
  },
  watch: {
    $route(to) {
      this.props = [];
      this.propsTitle = [];
      let propsObj = null;
      this.type = to.fullPath.substring(1);
      if (this.type === "categories") {
        this.type = "category";
        this.singularType = "category";
      }
      if (to.fullPath.substring(1) === "pages") {
        this.singularType = "page";
      } else if (to.fullPath.substring(1) === "posts") {
        this.singularType = "post";
      }
      switch (this.type) {
        case "posts":
          propsObj = postsObject();
          break;
        case "pages":
          propsObj = pagesObject();
          break;
        case "category":
          propsObj = categoriesObject();
          break;
        case "posts/trash":
          propsObj = postsTrashObject();
          break;
        case "pages/trash":
          propsObj = pagesTrashObject();
          break;
        default:
          propsObj = null;
      }
      if (propsObj !== null) {
        const loader = this.$loading.show();
        this.$store
          .dispatch("fetchData", { type: this.type })
          .then(() => loader.hide());
      }
      Object.assign(this.props, propsObj);
      for (const key in propsObj) {
        if (propsObj[key].show === true) {
          var singleObj = {};
          singleObj = propsObj[key];
          this.propsTitle.push(singleObj);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.languages_switcher {
  .languages_switcher__p {
    display: inline-block;
    margin: 0 5px;
    a {
      display: inline-block;
      background-color: transparent;
      color: #42b983;
      border: 1px solid #42b983;
      border-bottom: 0;
      text-align: center;
      padding-top: 5px;
      width: 50px;
      font-size: 18px;
      text-decoration: none;
      text-transform: uppercase;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}

.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #42b983;
  }
}

.items {
  padding-right: 30px;

  .items_form_title {
    border-radius: 5px;
    background-color: #42b983;
    color: white;
    padding: 5px 25px;
    text-align: left;

    .items_form_title__columns {
      padding: 12px;
      padding-left: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .item_list {
    padding: 5px 25px;
    border: 2px solid #42b983;
    border-top: none;
    margin-top: -15px;
    padding-top: 25px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .single_row_item {
      border-bottom: 1px solid #ccc;
      align-content: center;
      align-items: center;
      padding: 15px 0;
      margin: 0;

      h5 {
        font-weight: normal;
        line-height: 30px;
      }

      .single_row_item__p {
        color: #42b983;
        display: inline-block;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;

        a {
          color: #42b983;
          text-decoration: none;
        }
      }

      .add_or_edit_item_language {
        display: flex;
        div {
          flex: 0 0 10%;
        }
      }

      .single_row_item__p:after {
        content: "|";
        margin: 0 5px;
      }

      .single_row_item__p:last-child:after {
        content: "";
      }

      .language_icon {
        font-size: 14px;
      }

      .language_icon.language_icon__add {
        color: #42b983;
      }

      .language_icon.language_icon__edit {
        color: #9c27b0;
      }
    }

    .single_row_item:last-child {
      border-bottom: none;
    }

    .single_row_item > * {
      padding: 0;
    }
  }
}

.dialog_category_p {
  font-size: 18px;
}
</style>
