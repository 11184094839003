<template>
  <h4>{{ formatParam }}</h4>
  <div class="row">
    <div class="col-4">
      <form v-if="!updateTax" class="mt-4" @submit.prevent="createTax">
        <label for="size-w"
          >Item name <span class="required-field">*</span></label
        >
        <el-input
          v-model="name"
          type="text"
          required
          id="name"
          placeholder="Item name"
          class="mt-2"
        ></el-input>
        <div v-if="$route.params.type === 'colors'">
          <label class="mt-2" for="size-w"
            >Icon <span class="required-field">*</span></label
          >
          <ImageSelectorInput
            v-model="colorFeaturedImage"
            :multiple="false"
            :featuredImageSlug="'empty'"
          ></ImageSelectorInput>
        </div>

        <br />
        <el-button class="button_style_1 mt-4" @click="createTax">
          Add New Item
        </el-button>
      </form>
      <form v-else class="mt-4" @submit.prevent="updateTaxsF">
        <label for="size-w"
          >Item name <span class="required-field">*</span></label
        >
        <el-input
          v-model="name"
          :value="taxToUpdate.name"
          type="text"
          required
          id="name"
          placeholder="Tag name"
          class="mt-2"
        ></el-input>
        <div v-if="$route.params.type === 'colors'">
          <label class="mt-2" for="size-w"
            >Icon <span class="required-field">*</span></label
          >
          <ImageSelectorInput
            v-model="colorFeaturedImage"
            :multiple="false"
            :featuredImageSlug="
              taxToUpdate.icon.slug ? taxToUpdate.icon.slug : 'empty'
            "
          ></ImageSelectorInput>
        </div>
        <el-button class="button_style_1 mt-4" @click="updateTaxsF">
          Update item
        </el-button>
      </form>
    </div>
    <div class="col-8 mt-2">
      <el-table :data="taxs" stripe style="width: 100%">
        <el-table-column label="Item Name">
          <template #default="scope"
            >{{
              this.$route.params.type !== "colors"
                ? taxs[scope.$index].name
                : taxs[scope.$index].title
            }}
            <div class="danger-area">
              <p
                @click="
                  () => {
                    if (this.$route.params.type !== 'colors') {
                      this.taxToUpdate = taxs[scope.$index];
                      this.updateTax = true;
                      this.name = taxs[scope.$index].name;
                    } else {
                      this.taxToUpdate = taxs[scope.$index];
                      this.updateTax = true;
                      this.name = taxs[scope.$index].title;
                    }
                  }
                "
              >
                Edit
              </p>
              <p
                class="delete"
                @click="() => deleteTaxs(taxs[scope.$index].id)"
              >
                Delete
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="havePagination">
        <el-pagination
          layout="prev, pager, next"
          :total="totalNum"
          :page-count="Math.ceil(totalNum / 20)"
          :current-page="pageNo"
          @current-change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ImageSelectorInput from "../../components/Tiptap/ImageSelectorInput.vue";
import {
  ElButton,
  ElInput,
  ElNotification,
  ElTable,
  ElTableColumn,
  ElMessage,
  ElMessageBox,
} from "element-plus";
export default {
  components: {
    ElButton,
    ElInput,
    ElTable,
    ElTableColumn,
    ImageSelectorInput,
  },
  data() {
    return {
      name: "",
      taxs: [],
      havePagination: false,
      totalNum: 0,
      pageNo: 1,
      updateTax: false,
      taxToUpdate: {},
      colorFeaturedImage: "",
    };
  },
  methods: {
    createTax() {
      if (
        this.name === "" ||
        (this.$route.params.type === "colors" && this.colorFeaturedImage === "")
      ) {
        ElNotification({
          title: "Warning!",
          message: "Mandatory fields must be filled in!",
          type: "warning",
        });
      } else {
        axios
          .post(
            `${process.env.VUE_APP_SHARED_API}api/${this.$route.params.type}`,
            this.$route.params.type !== "colors"
              ? {
                  name: this.name,
                }
              : {
                  title: this.name,
                  icon_id: this.colorFeaturedImage,
                },
            {
              headers: {
                Authorization: "Bearer " + this.$cookies.get("bearerToken"),
              },
            }
          )
          .then((res) => {
            if (res.data.id) {
              this.name = "";
              if (this.$route.params.type !== "colors") {
                this.taxs.push({
                  id: res.data.id,
                  name: res.data.name,
                });
              } else {
                this.taxs.push({
                  id: res.data.id,
                  title: res.data.title,
                  icon_id: res.data.icon_id,
                });
                this.name = "";
                this.colorFeaturedImage = 0;
                this.$store.dispatch("resetImgSelector");
              }
            } else {
              ElNotification({
                title: "Warning!",
                message: "Something went wrong, please try later!",
                type: "warning",
              });
            }
          });
      }
    },
    updateTaxsF() {
      axios
        .put(
          `${process.env.VUE_APP_SHARED_API}api/${this.$route.params.type}/${this.taxToUpdate.id}`,
          this.$route.params.type !== "colors"
            ? {
                name: this.name,
              }
            : {
                title: this.name,
                icon_id: this.colorFeaturedImage,
              },
          {
            headers: {
              Authorization: "Bearer " + this.$cookies.get("bearerToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.id) {
            this.name = "";
            let toUpdateByIndex = this.taxs.findIndex(
              (x) => x.id == res.data.id
            );
            this.taxs[toUpdateByIndex].name = res.data.name;
            this.updateTax = false;
          } else {
            ElNotification({
              title: "Warning!",
              message: "Something went wrong, please try later!",
              type: "warning",
            });
          }
        });
    },
    async fetchTax(pageNo) {
      const loader = this.$loading.show();
      return axios
        .get(
          `${process.env.VUE_APP_SHARED_API}api/${
            this.$route.params.type
          }?per_page=20${pageNo ? `&page=${pageNo}` : ``}`,
          {
            headers: {
              Authorization: "Bearer " + this.$cookies.get("bearerToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.current_page) {
            this.taxs = res.data.data;
            console.log(this.taxs);
            loader.hide();
          }
          if (res.data.last_page > 1) {
            this.havePagination = true;
            this.totalNum = res.data.total;
          }
        });
    },
    pageChange(pageNo) {
      this.pageNo = pageNo;
      this.fetchTax(pageNo);
    },
    deleteTaxs(id) {
      ElMessageBox.confirm("Are you sure?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        axios
          .delete(
            `${process.env.VUE_APP_SHARED_API}api/${this.$route.params.type}/${id}`,
            {
              headers: {
                Authorization: "Bearer " + this.$cookies.get("bearerToken"),
              },
            }
          )
          .then((res) => {
            let deleteByIdnex = this.taxs.findIndex((x) => x.id == id);
            if (res.status === 200) {
              ElMessage({
                type: "success",
                message: "Delete completed",
              });
              this.taxs.splice(deleteByIdnex, 1);
            }
          });
      });
    },
  },
  mounted() {
    this.fetchTax();
  },
  watch: {
    name: function (newVal, oldVal) {
      if (!newVal && this.updateTax === true) this.updateTax = true; // find way how to reset update mode?
    },
    $route(to, from) {
      if (this.$route.params.type) {
        this.taxs = [];
        this.fetchTax();
      }
    },
  },
  computed: {
    formatParam: function () {
      return this.$route.params.type.replace("-", " ");
    },
  },
};
</script>

<style lang="scss" scoped>
.required-field {
  color: red;
  font-weight: bold;
}
.danger-area {
  width: 100%;
  display: flex;
  opacity: 0;
  transition: all 0.3s;
}
tr:hover .danger-area {
  transition: all 0.3s;
  opacity: 1;
}
.danger-area p {
  margin: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  color: #2271b1;
  margin-right: 10px;
  line-height: 10px;
}
.danger-area p.delete {
  color: #b32d2e;
}
h4 {
  text-transform: capitalize;
}
</style>