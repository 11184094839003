<template>
  <div class="row">
    <div class="col">
      <label for="value"
        >Value Indicators <span class="required-field">*</span></label
      >
      <div>
        <div v-if="valueIndicators.length > 0">
          <div v-for="(item, index) in valueIndicators" :key="index">
            <div class="row-wrapper p-2">
              <label for="value-indicators-icon">Icon </label>
              Dropdown
              <label class="mt-2" for="title">Title </label>
              <el-input
                v-model="item.title"
                required
                id="title"
                placeholder="Title"
                class="mb-2"
              ></el-input>
              <div class="text-repeeater">
                <p class="p-0 m-0">Text list</p>
                <div
                  class="border p-2 bg-light"
                  v-if="item.textList.length > 0"
                >
                  <div
                    class="row-wrapper"
                    v-for="(listItem, index) in item.textList"
                    :key="index"
                  >
                    <div
                      class="delete-row"
                      style="z-index: 1"
                      @click="
                        () => {
                          item.textList.splice(index, 1);
                        }
                      "
                    >
                      <svg
                        class="svg-inline--fa fa-trash-alt fa-w-14"
                        data-v-b4ae8fbc=""
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="trash-alt"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                        ></path>
                      </svg>
                    </div>
                    <el-input
                      v-model="listItem.text"
                      required
                      id="title"
                      placeholder="Text list item"
                      :class="index != 0 ? 'mt-2' : ''"
                    ></el-input>
                  </div>
                </div>
                <el-button
                  @click="
                    () => {
                      item.textList.push({
                        id: item.textList.length + 1,
                        text: '',
                      });
                    }
                  "
                  class="button_style_1 mt-2"
                >
                  Add New Row</el-button
                >
              </div>
              <div
                class="delete-row"
                @click="
                  () => {
                    this.valueIndicators.splice(index, 1);
                  }
                "
              >
                <svg
                  class="svg-inline--fa fa-trash-alt fa-w-14"
                  data-v-b4ae8fbc=""
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="trash-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <el-button
          class="button_style_1 mt-2"
          @click="
            () => {
              this.valueIndicators.push({
                id: this.valueIndicatorsCounter++,
                imageUrl: '',
                title: '',
                textList: [],
              });
            }
          "
        >
          Add New Row
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ElButton, ElInput } from "element-plus";
export default {
  props: {
    postType: {
      type: String,
    },
    serverData: {
      require: false,
    },
  },
  components: {
    ElButton,
    ElInput,
  },
  data() {
    return {
      valueIndicators: [],
      valueIndicatorsCounter: 2,
    };
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "BIND_VALUE_INDICATORS") {
        this.valueIndicators = this.$store.state.cf.cf.artworks.valueIndicators;
      }
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$store.dispatch("updateValue", {
          type: this.postType,
          data: {
            valueIndicators: this.valueIndicators,
          },
        });
      }
      if (mutation.type === "COLLECT_FROM_CF") {
        this.valueIndicators = state.cf.cf.artworks.valueIndicators;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.delete-row > svg {
  color: white;
  fill: white;
}
.required-field {
  color: red;
  font-weight: bold;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
</style>