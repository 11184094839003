<template>
  <div>
    <Login v-if="$store.state.user.user == null"></Login>
    <div v-else>
      <section class="navigation-bar">
        <div class="overlay"></div>
        <NavMenu></NavMenu>
      </section>
      <section class="main-section">
        <router-view/>
      </section>
    </div>
  </div>

</template>

<script>
import NavMenu from './views/NavMenu'
import Login from './views/user/Login'

export default {
  components: {
    NavMenu,
    Login
  },
  mounted () {
    const bearerToken = this.$cookies.get('bearerToken')
    if (bearerToken) {
      this.$store.dispatch('setUserObject', bearerToken)
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
