<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">
      <p>{{ $route.query.mode }} {{ $route.query.singular }}</p>
    </div>
  </div>
  <div class="single-post">
    <div>
      <form @submit.prevent="">
        <div class="row">
          <div class="col-md-8 single_post_main_box">
            Title <span class="required-field">*</span>
            <el-input
              v-model="postData.title"
              required
              class="add-new-item__title"
              placeholder="Title"
            ></el-input>
            Overview <span class="required-field">*</span>
            <tiptap v-model="postData.content" />
            <el-tabs
              v-if="$route.params.type == 'artworks'"
              type="border-card"
              class="mt-4"
              v-model="general"
              @tab-click="() => {}"
            >
              <el-tab-pane label="General" current-name="general">
                <general :postType="this.$route.params.type" />
              </el-tab-pane>
              <el-tab-pane label="Techniques" name="techniques">
                <techniques :postType="this.$route.params.type" />
              </el-tab-pane>
              <el-tab-pane label="Gallery" name="gallery">
                <gallery :postType="this.$route.params.type" />
              </el-tab-pane>
              <el-tab-pane label="Collector Trends" name="colectortrends">
                <collector-trends :postType="this.$route.params.type" />
              </el-tab-pane>
              <el-tab-pane label="Value Indicators" name="valueindicators">
                <value-indicators :postType="this.$route.params.type" />
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="col-md-4 single_post_sidebar">
            <div class="row">
              <div class="col-md-12">
                <div class="buttons_section single_post_box">
                  <el-button class="button_style_1" @click="createPost">
                    Publish
                  </el-button>
                </div>
              </div>
              <div class="col-md-12">
                <div class="col-xl-12">
                  <div class="post_status single_post_box">
                    <h3 class="single_post_box__h3">Status</h3>
                    <el-select v-model="postData.status" placeholder="Select">
                      <el-option
                        v-for="item in postData.statuses"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="col-xl-12">
                  <div class="post_status single_post_box">
                    <h3 class="single_post_box__h3">Slug</h3>
                    <el-input
                      v-model="postData.slug"
                      required
                      placeholder="Slug"
                      :value="postData.slug"
                    ></el-input>
                  </div>
                </div>
              </div>
              <div class="col-xl-12">
                <div class="single_post_box">
                  <h3 class="single_post_box__h3">
                    Featured image <span class="required-field">*</span>
                  </h3>
                  <ImageSelectorInput
                    v-model="postData.featuredImage"
                    :multiple="false"
                    :featuredImageSlug="'empty'"
                  ></ImageSelectorInput>
                </div>
              </div>
              <div class="col-xl-12">
                <div class="single_post_box">
                  <h3 class="single_post_box__h3">Tags</h3>
                  <div v-if="postData.tags.length > 0">
                    <ul class="tags-list">
                      <el-input
                        v-model="searchQuery"
                        placeholder="Search tags"
                        class="mb-4"
                      ></el-input>
                      <el-checkbox-group v-model="postData.choosenTags">
                        <li
                          :class="[
                            postData.primaryTagId == tag.id
                              ? 'active-star tag-list-item'
                              : 'tag-list-item',
                          ]"
                          v-for="tag in filterTagList"
                          :key="tag.id"
                        >
                          <el-checkbox size="large" :label="tag.id"
                            >{{ tag.name }}
                          </el-checkbox>
                          <font-awesome-icon
                            @click="() => addTagAsPrimary(tag.id)"
                            :icon="['fa', 'star']"
                          />
                        </li>
                      </el-checkbox-group>
                    </ul>
                  </div>
                </div>
                <div v-if="customTaxonomiesRes.length > 0">
                  <div
                    v-for="customTax in customTaxonomiesRes"
                    :key="customTax.id"
                    class="single_post_box"
                  >
                    <tax @collectTaxs="collectTaxs" :tax="customTax" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Tiptap from "../../../components/Tiptap/Tiptap";
import {
  ElTabs,
  ElTabPane,
  ElSelect,
  ElOption,
  ElNotification,
  ElInput,
  ElCheckbox,
  ElCheckboxGroup,
} from "element-plus";
import ImageSelectorInput from "../../../components/Tiptap/ImageSelectorInput";
import slugify from "slugify";
import General from "../../../components/cf/General.vue";
import Gallery from "../../../components/cf/Gallery.vue";
import ValueIndicators from "../../../components/cf/ValueIndicators.vue";
import { validateForm } from "../../../helpers/validator";
import { createPost } from "../../../services/createPost";
import CollectorTrends from "../../../components/cf/CollectorTrends.vue";
import Techniques from "../../../components/cf/Techniques.vue";
import axios from "axios";
import Tax from "./Tax.vue";

export default {
  name: "New",
  components: {
    Tiptap,
    ImageSelectorInput,
    ElTabs,
    ElTabPane,
    ElSelect,
    ElOption,
    ElInput,
    General,
    Gallery,
    ValueIndicators,
    CollectorTrends,
    Techniques,
    ElCheckbox,
    ElCheckboxGroup,
    Tax,
  },
  data() {
    return {
      postData: {
        title: "",
        slug: "",
        content: "",
        featuredImage: 0,
        statuses: [
          {
            id: 2,
            name: "publish",
          },
          {
            id: 1,
            name: "draft",
          },
        ],
        status: "publish",
        tags: [],
        choosenTags: [],
        primaryTagId: 0,
      },
      customTaxonomies: ["collector-items", "colors", "types"],
      requiredTaxonomies: ["collector-items", "colors", "types"],
      customTaxonomiesRes: [],
      choosenTaxonomies: [],
      runCreate: false,
      searchQuery: "",
    };
  },
  watch: {
    "postData.title": {
      handler: function (newVal, oldVal) {
        return (this.postData.slug = slugify(newVal.toLowerCase()));
      },
      deep: true,
    },
    "postData.choosenTags": {
      handler: function (newVal, oldVal) {
        console.log(this.postData.choosenTags);
        if (!newVal.includes(this.postData.primaryTagId)) {
          this.postData.primaryTagId = 0;
        }
      },
    },
  },
  methods: {
    collectTaxs(value) {
      this.choosenTaxonomies.push(value);
    },
    async createPost() {
      // this.$store.dispatch("collectData");
      // if (
      //   this.choosenTaxonomies[0].taxs.length
      //     ? this.choosenTaxonomies[0].taxs.lengt > 1
      //     : this.choosenTaxonomies[0].taxs > 0 &&
      //       this.choosenTaxonomies[1].taxs.length
      //     ? this.choosenTaxonomies[1].taxs.lengt > 1
      //     : this.choosenTaxonomies[1].taxs > 0 &&
      //       this.choosenTaxonomies[2].taxs.length
      //     ? this.choosenTaxonomies[2].taxs.lengt > 1
      //     : this.choosenTaxonomies[2].taxs > 0
      // ) {
      //   this.runCreate = true;
      // } // BUGS!

      if (
        this.postData.featuredImage < 1 ||
        this.postData.title === "" ||
        this.postData.content === ""
        // this.runCreate !== false
      ) {
        ElNotification({
          title: "Warning!",
          message: "Mandatory fields must be filled in!",
          type: "warning",
        });
      } else {
        if (this.postData.slug === "" && this.postData.title !== "") {
          ElNotification({
            title: "Warning!",
            message: "Please create your slug!",
            type: "warning",
          });
        } else {
          this.$store.dispatch("collectData");
          let isValid = await validateForm(this.$route.params.type);
          if (!isValid) {
            ElNotification({
              title: "Warning!",
              message: "Mandatory fields must be filled in!",
              type: "warning",
            });
          } else {
            const loader = this.$loading.show();
            return await createPost(
              this.$route.params.type,
              this.postData,
              this.choosenTaxonomies
            )
              .then((res) => {
                if (res.data?.id !== 0) {
                  axios
                    .post(
                      `${process.env.VUE_APP_SHARED_API}api/collector_trends`,
                      {
                        artwork_id: res.data.id,
                        collector_trends:
                          this.$store.state.cf.cf.artworks.collectorTrends,
                      },
                      {
                        headers: {
                          Authorization:
                            "Bearer " + this.$cookies.get("bearerToken"),
                        },
                      }
                    )
                    .then((r) => {
                      if (r.data[0].id) {
                        loader.hide();
                        ElNotification({
                          title: "Yea!",
                          message: "Artwork successfully created.",
                          type: "success",
                        });
                        if (this.$route.params.type === "artworks")
                          this.$router.push("/artworks");
                      }
                    });
                }
              })
              .catch((e) => {
                loader.hide();
                ElNotification({
                  title: "Warning!",
                  message:
                    "The slug is taken, please try another one or keep our suggestion.",
                  type: "warning",
                });
                this.postData.slug = this.postData.slug.concat("-1");
              });
          }
        }
      }
    },
    addTagAsPrimary(id) {
      if (this.postData.primaryTagId === id) {
        this.postData.primaryTagId = 0;
      } else {
        this.postData.primaryTagId = id;
        if (!this.postData.choosenTags.includes(id))
          this.postData.choosenTags.push(id);
      }
    },
  },
  mounted() {
    this.$store.dispatch("resetCollectFromCf");
    axios
      .get(`${process.env.VUE_APP_SHARED_API}api/tags?per_page=15`, {
        headers: {
          Authorization: "Bearer " + this.$cookies.get("bearerToken"),
        },
      })
      .then((res) => {
        if (res.data.current_page) {
          this.postData.tags = res.data.data;
        }
      });
    this.customTaxonomies.map((tax, i) => {
      axios
        .get(`${process.env.VUE_APP_SHARED_API}api/${tax}?per_page=15`, {
          headers: {
            Authorization: "Bearer " + this.$cookies.get("bearerToken"),
          },
        })
        .then((res) => {
          if (res.data.current_page) {
            this.customTaxonomiesRes.push({
              title: tax,
              id: i,
              data: res.data.data,
              choosenTax: [],
            });
          }
        });
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.choosenTaxonomies = [];
      }
    });
  },
  computed: {
    filterTagList() {
      return this.postData.tags.filter((t) => {
        if (t.title !== undefined) {
          return t.title.toLowerCase().includes(this.searchQuery.toLowerCase());
        } else {
          return t.name.toLowerCase().includes(this.searchQuery.toLowerCase());
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-title-section-content {
  p {
    margin-bottom: 0;
    text-transform: capitalize;
  }
}

.single_post_main_box {
  padding: 25px;
  background-color: #f2f2f2;
  border-radius: 5px;
  height: 100%;
}

.single_post_box {
  background-color: #f2f2f2;
  padding: 25px;
  margin-bottom: 25px;
  border-radius: 5px;

  .single_post_box__h3 {
    font-size: 20px;
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  .featured_image_single {
    max-width: 400px;
    margin: 0 auto;
  }
}

.category_checkbox:active,
.category_checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.categories_box {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  height: 150px;
  overflow-y: scroll;
}

.single_category__div {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;

  .category_checkbox {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
  }

  label {
    font-size: 14px;
    cursor: pointer;
  }

  .category_checkbox:checked {
    background-color: #e9ecee;
    border: 1px solid #42b983;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }

  .category_checkbox:checked:after {
    content: "\2714";
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: #42b983;
  }
}

.buttons_section {
  text-align: right;
}
.tip_tap_editor {
  max-height: 650px;
  height: 100%;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.delete-row > svg {
  color: white;
  fill: white;
}
.required-field {
  color: red;
  font-weight: bold;
}
.tags-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
  max-height: 150px;
  height: 100%;
  overflow-y: scroll;
}
.tags-list li {
  padding-left: 10px;
}
.tag-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}
.tag-list-item svg {
  width: 18px;
  height: 18px;
  margin-left: 30px;
  cursor: pointer;
  margin-right: 10px;
}
.active-star svg {
  color: #dddd00;
}
</style>