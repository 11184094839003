import api from '../../services/api'

export default {
  state: {
    items: null,
    paginationLinks: null,
    currentPage: null
  },
  mutations: {
    SET_ITEMS_DATA(state, data) {
      state.items = data
    },
    SET_PAGINATION_LINKS(state, data) {
      state.paginationLinks = data
      state.currentPage = data.current_page
    },
    DELETE_ITEM_ITEMS(state, id) {
      state.items.map((singleState, index) => {
        if (singleState.id === id) {
          state.items.splice(index, 1)
        } else if (singleState.post_id === id) {
          state.items.splice(index, 1)
        }
      })
    }
  },
  actions: {
    fetchData({ commit }, obj) {
      return api.get('api/' + obj.type + '?per_page=30&page=' + obj.page).then((response) => {
        commit('SET_PAGINATION_LINKS', response.data)
        commit('SET_ITEMS_DATA', response.data.data)
      })
    },
    deleteItem({ commit }, obj) {
      return api.delete('api/' + obj.type + '/' + obj.id).then((response) => {
        commit('DELETE_ITEM_ITEMS', obj.id)
      })
    },
    restoreItem({ commit }, obj) {
      return api.post('api/' + obj.type + '/trash/' + obj.id).then((response) => {
        commit('DELETE_ITEM_ITEMS', obj.id)
      })
    },
    permanentDelete({ commit }, obj) {
      return api.delete('api/' + obj.type + '/trash/' + obj.id).then((response) => {
        commit('DELETE_ITEM_ITEMS', obj.id)
      })
    }
  }
}
