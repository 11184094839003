<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">
      {{ this.$route.name }}
    </div>
  </div>
  <div class="items">
    <el-table :data="posts" style="width: 100%">
      <el-table-column prop="title" label="Title" />
      <el-table-column label="Created at">
        <template #default="scope">
          {{ $moment(posts[scope.$index].created_at).format("DD MMM YYYY") }}
        </template>
      </el-table-column>
      <el-table-column label="Actions">
        <template #default="scope">
          <el-button
            size="small"
            type="success"
            @click="
              () =>
                $router.push({
                  name: 'edit_item',
                  params: { type: 'artworks', id: posts[scope.$index].id },
                  query: {
                    v: '2',
                    mode: 'edit',
                    singular: 'artwork',
                  },
                  props: true,
                })
            "
            >Edit post</el-button
          >
          <el-button size="small" type="danger">Delete post</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="havePagination">
      <el-pagination
        layout="prev, pager, next"
        :total="totalNum"
        :page-count="Math.ceil(totalNum / 20)"
        :current-page="pageNo"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ElTable, ElTableColumn, ElButton, ElPagination } from "element-plus";
export default {
  data() {
    return {
      posts: [],
      isPostLoaded: false,
      havePagination: false,
      totalNum: 0,
      pageNo: 1,
    };
  },
  components: {
    ElTable,
    ElTableColumn,
    ElButton,
    ElPagination,
  },
  methods: {
    fetchPosts(pageNo) {
      return axios.get(
        `${
          process.env.VUE_APP_SHARED_API
        }api/${this.$route.name.toLowerCase()}?per_page=20${
          pageNo ? `&page=${pageNo}` : ``
        }`,
        {
          headers: {
            Authorization: "Bearer " + this.$cookies.get("bearerToken"),
          },
        }
      );
    },
    pageChange(pageNo) {
      this.pageNo = pageNo;
      const loader = this.$loading.show();
      this.fetchPosts(pageNo).then((res) => {
        if (res.data.data.length > 0) {
          this.posts = res.data.data;
          loader.hide();
        }
      });
    },
  },
  computed: {},
  mounted() {
    const loader = this.$loading.show();
    try {
      this.fetchPosts().then((res) => {
        if (res.data.data.length > 0) {
          this.posts = res.data.data;
          loader.hide();
          if (res.data.last_page > 1) {
            this.totalNum = res.data.total;
            this.havePagination = true;
          }
        }
      });
    } catch (e) {
      this.$loading.show();
    }
  },
};
</script>

<style lang="scss" scoped>
.items {
  padding-right: 30px;

  .items_form_title {
    border-radius: 5px;
    background-color: #42b983;
    color: white;
    padding: 5px 25px;
    text-align: left;

    .items_form_title__columns {
      padding: 12px;
      padding-left: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .item_list {
    padding: 5px 25px;
    border: 2px solid #42b983;
    border-top: none;
    margin-top: -15px;
    padding-top: 25px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .single_row_item {
      border-bottom: 1px solid #ccc;
      align-content: center;
      align-items: center;
      padding: 15px 0;
      margin: 0;

      h5 {
        font-weight: normal;
        line-height: 30px;
      }

      .single_row_item__p {
        color: #42b983;
        display: inline-block;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;

        a {
          color: #42b983;
          text-decoration: none;
        }
      }

      .add_or_edit_item_language {
        display: flex;
        div {
          flex: 0 0 10%;
        }
      }

      .single_row_item__p:after {
        content: "|";
        margin: 0 5px;
      }

      .single_row_item__p:last-child:after {
        content: "";
      }

      .language_icon {
        font-size: 14px;
      }

      .language_icon.language_icon__add {
        color: #42b983;
      }

      .language_icon.language_icon__edit {
        color: #9c27b0;
      }
    }

    .single_row_item:last-child {
      border-bottom: none;
    }

    .single_row_item > * {
      padding: 0;
    }
  }
}
</style>