<template>
  <div>
    <div v-if="mediaType === 'image'">
      <el-button v-if="showButton" @click="showModal();" class="button_style_1">Select image
      </el-button>
    </div>
    <div v-else-if="mediaType === 'gallery'">
      <el-button v-if="showButton" @click="showModal(); " class="button_style_1">Select gallery
      </el-button>
    </div>
    <div v-else-if="mediaType === 'audio'">
      <el-button v-if="showButton" @click="showModal(); " class="button_style_1">Select audio file
      </el-button>
    </div>

    <el-dialog
      v-model="dialog"
      width="900px"
    >

      <header class="tab-header media_tab_header">
        <button v-if="mediaType === 'image'" @click="tab = 1" :class="{ active: tab == 1 }"
                type="button">Images
        </button>
        <button v-if="mediaType === 'gallery'" @click="tab = 1" :class="{ active: tab == 1 }"
                type="button">Images
        </button>
        <button v-if="mediaType === 'document'" @click="tab = 1" :class="{ active: tab == 1 }"
                type="button">Documents
        </button>
        <button v-if="mediaType === 'audio'" @click="tab = 1" :class="{ active: tab == 1 }"
                type="button">Audio Files
        </button>
        <button @click="tab = 0;" :class="{ active: tab == 0 }" type="button">
          Upload (Drag 'n' Drop)
        </button>
      </header>

      <div v-if="tab == 1">
        <div v-if="mediaType === 'image'" class="d-flex flex-row flex-wrap media_images">
          <div
            v-for="(img, i) in $store.state.media.images"
            :key="'img-' + i"
            class="ma-2 selectable"
            :class="{ selected: selectedImage && img.id === selectedImage.id}"
          >
            <img
              :src="img.src"
              :key="'img-' + i"
              :alt="img.alt"
              @click="selectImage(img)"
              style="height: 100%"
            >
            <div
              class="delete_media"
              @click="confirmDelete = true; mediaForDelete(img.id)">
              <i class="fas fa-trash-alt"></i>
            </div>
          </div>
        </div>

        <div v-if="mediaType === 'gallery'" class="d-flex flex-row flex-wrap media_images">
          <div
            v-for="(img, i) in $store.state.media.images"
            :key="'img-' + i"
            class="ma-2 selectable"
            :class="{ selected: selectedImage && img.id === selectedImage.id}"
            style="max-width: 100px; max-height: 100px; margin: 3px">
            <img
              :src="img.src"
              :key="'img-' + i"
              :alt="img.alt"
              @click="selectGalleryImage(img)"
              style="height: 100%"
            >
            <div
              class="delete_media"
              @click="confirmDelete = true; mediaForDelete(img.id)">
              <i class="fas fa-trash-alt"></i>
            </div>
          </div>
        </div>

        <div v-if="mediaType === 'document'" class="d-flex flex-row flex-wrap media_images">
          <div
            v-for="(doc, i) in $store.state.media.documents"
            :key="'doc-' + i"
            @click="selectDocument({id: doc.id, src: doc.src, name: doc.alt, type: 'document'})">
            <div
              class="media_document ma-2 selectable"
              :class="{ selected: selectedDocument && doc.id === selectedDocument.id}">
              <i class="far fa-file"></i>
              <p>{{ doc.alt }}</p>
            </div>
            <div
              class="delete_media"
              @click="confirmDelete = true; mediaForDelete(doc.id)">
              <i class="fas fa-trash-alt"></i>
            </div>
          </div>
        </div>

        <div v-if="mediaType === 'audio'" class="d-flex flex-row flex-wrap media_images">
          <div
            v-for="(singleAudio, i) in $store.state.media.audio"
            :key="'doc-' + i"
            class="ma-2 selectable"
            :class="{ selected: selectedAudio && singleAudio.id === selectedAudio.id}"
            @click="selectAudio(singleAudio)">
            <div
              class="media_document ma-2 selectable"
              :class="{ selected: selectedDocument && doc.id === selectedDocument.id}">
              <i class="fas fa-file-audio"></i>
              {{ singleAudio.alt }}
            </div>
            <div
              class="delete_media"
              @click="confirmDelete = true; mediaForDelete(singleAudio.id)">
              <i class="fas fa-trash-alt"></i>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tab == 0">
        <div class="drag-and-drop__box">
          <div v-bind="getRootProps()">
            <input v-bind="getInputProps()">
            <p v-if="isDragActive">Drop the files here ...</p>
            <p v-else>Drag 'n' drop some files here, or click to select files</p>
          </div>
        </div>
      </div>
      <el-button
        v-if="mediaType === 'image'"
        @click="insertImage"
        class="button_style_1"
        :title="validImage ? '' : 'Image URL needs to be valid'"
        :disabled="!selectedImage"
      >
        Add Image
      </el-button>

      <el-button
        v-if="mediaType === 'gallery'"
        @click="insertGallery"
        class="button_style_1"
        :title="validImage ? '' : 'Image URL needs to be valid'"
        :disabled="!selectGalleryImage"
      >
        Add Gallery
      </el-button>

      <el-button
        v-else-if="mediaType === 'document'"
        @click="insertDocument"
        class="button_style_1"
        :disabled="!selectedDocument"
      >
        Add Document
      </el-button>

      <el-button
        v-else-if="mediaType === 'audio'"
        @click="insertAudio"
        class="button_style_1"
        :disabled="!selectedAudio"
      >
        Add Audio
      </el-button>
      <el-button
        class="button_style_2"
        @click="dialog = false"
      >
        Close
      </el-button>

    </el-dialog>
    <el-dialog
      v-model="confirmDelete"
      title="Potvrda brisanja"
      width="30%"
      :before-close="handleClose"
    >
      <span>Da li ste sigurni da želite da obrišete?</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="confirmDelete = false" class="button_style_1">Otkaži</el-button>
          <el-button type="primary" class="button_style_2" @click="confirmDelete = false; deleteMedia(mediaForDeleteData)"
          >Obriši</el-button
          >
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
/**
 * Example of a custom Image selector
 * Key is to emit a select-file event when a file needs to be added
 */
import api from '../../services/api'
import { useDropzone } from 'vue3-dropzone'
// import { getAllImages, getAllDocuments, getAllAudio } from '../../services/media'
import app from '../../main'
import { ElMessageBox } from 'element-plus'
import { useStore } from 'vuex'

export default {
  name: 'FileSelector',
  setup () {
    const store = useStore()
    function onDrop (acceptFiles, rejectReasons) {
      const globalProp = app.config.globalProperties
      const loader = globalProp.$loading.show()
      const formData = new FormData()
      formData.append('file', acceptFiles[0])
      api.post('/api/media', formData).then((results) => {
        loader.hide()
        store.dispatch('setMediaData', 'image')
        store.dispatch('setMediaData', 'documents')
        store.dispatch('setMediaData', 'audio')
        globalProp.$notify({
          title: 'Success',
          message: 'Your media is successfully uploaded!',
          type: 'success'
        })
      })
    }

    const {
      getRootProps,
      getInputProps,
      ...rest
    } = useDropzone({ onDrop })

    const handleClose = (done) => {
      ElMessageBox.confirm('Are you sure to close this dialog?')
        .then(() => {
          done()
        })
        .catch(() => {
          // catch error
        })
    }

    return {
      getRootProps,
      getInputProps,
      handleClose,
      ...rest
    }
  },
  props: {
    fileSelect: {
      type: Function
    },
    showButton: {
      type: Boolean,
      default: true
    },
    mediaType: {
      type: String
    }
  },
  data () {
    // Some public domain images from wikimedia.
    return {
      dialog: false,
      confirmDelete: false,
      selectedImage: null,
      mediaForDeleteData: null,
      selectedDocument: null,
      selectedAudio: null,
      images: [],
      gallery: [],
      audio: [],
      documents: [],
      imageSrc: '',
      command: null,
      show: false,
      tab: 1,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        addRemoveLinks: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    }
  },
  computed: {
    validImage () {
      return (
        this.imageSrc.match(/unsplash/) !== null ||
        this.imageSrc.match(/\.(jpeg|jpg|gif|png)$/) != null
      )
    }
  },
  methods: {
    selectImage (img) {
      this.selectedImage = img
    },
    fetchData (type) {
      this.$store.dispatch('setMediaData', type)
    },
    selectDocument (document) {
      this.selectedDocument = document
    },
    mediaForDelete (id) {
      this.mediaForDeleteData = id
    },
    deleteMedia (id) {
      this.$store.dispatch('deleteMedia', id)
    },
    selectGalleryImage (img) {
      this.selectedImage = img

      function imageExists (gallery) {
        return gallery.some(function (el) {
          return el.id === img.id
        })
      }

      if (this.gallery.length === 0) {
        this.gallery.push(img)
      } else {
        if (imageExists(this.gallery)) {
        } else {
          this.gallery.push(img)
        }
      }
    },

    selectAudio (audio) {
      this.selectedAudio = audio
    },

    showModal () {
      this.dialog = true
      this.$emit('openModal')
    },

    insertImage () {
      this.fileSelect(this.selectedImage)
      this.closeModal()
    },

    insertGallery () {
      this.fileSelect(this.gallery)
      this.closeModal()
    },

    insertDocument () {
      this.fileSelect(this.selectedDocument)
      this.closeModal()
    },

    insertAudio () {
      this.fileSelect(this.selectedAudio)
      this.closeModal()
    },

    closeModal () {
      this.show = false
      this.imageSrc = ''
      this.tab = 1
    }
  }
}
</script>

<style lang="scss" scoped>

.media_images {
  border: 1px solid #42b983;
  border-top: none;
  padding: 15px;
  margin-bottom: 30px;

  .delete_media {
    position: absolute;
    top: -5px;
    right: -5px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: red;
    border-radius: 50%;
    align-items: center;
    svg {
      color: white;
    }
  }

  .selectable {
    max-width: 100px;
    max-height: 100px;
    margin: 3px;
  }

  div {
    position: relative;
  }

  div.selected:before {
    content: '';
    background-color: rgba(0, 0, 0, .4);
    position: absolute;
    height: 100%;
    width: 100%;
  }

  div.selected:after {
    content: '\2714';
    font-size: 24px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: #42b983;
  }
}

.selectable {
  cursor: pointer;
}

.drag-and-drop__box {
  height: 300px;
  border: 1px solid #42b983;
  border-top: 0;
  cursor: pointer;
  margin-bottom: 30px;

  div {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    p {
      font-size: 18px;
    }
  }
}

.media_document {
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 0 8px;
  flex-direction: column;
  text-align: center;

  svg {
    font-size: 30px;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 0;
  }
}

.tab-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #222;
}

.tab-header button {
  color: #222;
  background: none;
  border: 0;
  flex: 1;
  padding: 5px 10px;
  cursor: pointer;
}

.tab-header button.active {
  color: #fff;
}

.tab-header.media_tab_header button {
  padding: 20px 0;
  font-size: 20px;
}

.tab-header.media_tab_header button.active {
  background-color: #42b983;
}

.tab-header.media_tab_header {
  border-bottom: 1px solid #42b983;
}

</style>
