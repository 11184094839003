import store from '../store'
export const validateForm = async (vuexStateName) => {
    let isPass = true;
    let fieldsToValid = store.state.cf.cf[vuexStateName];
    for (const [key, value] of Object.entries(fieldsToValid)) {
        if (store.state.cf.cf[vuexStateName].validateSubFields === true) {
            if (!fieldsToValid.nonRequired.includes(key) || !fieldsToValid.nonRequiredSub.includes(key)) {
                if (Array.isArray(value) && value?.length < 1) {
                    return isPass = false;
                } else if (value === 0 || value === "") {
                    return isPass = false;
                } else if (Array.isArray(value) && value?.length > 0) {
                    value.map(item => {
                        for (const [key, v] of Object.entries(item)) {
                            if (!fieldsToValid.nonRequiredSub.includes(key)) {
                                if (v === 0 || v === "" || v === "Please select") {
                                    return isPass = false;
                                } else if (Array.isArray(v) && v?.length > 0) {
                                    v.map(i => {
                                        for (const [k, j] of Object.entries(i)) {
                                            if (!fieldsToValid.nonRequiredSub.includes(k)) {
                                                if (j === 0 || j === "") {
                                                    return isPass = false;
                                                }
                                            }
                                        }
                                    })
                                }
                            }
                        }
                    })
                }
            }
        } else {
            if (!fieldsToValid.nonRequired.includes(key)) {
                if (value === 0 || value === "" || value?.length) {
                    return isPass = false;
                }
            }
        }
    }

    return isPass;
}