<template>
  <div class="row">
    <div class="col">
      <label for="value"
        >Techniques <span class="required-field">*</span></label
      >
      <div>
        <div v-if="techniques.length > 0">
          <div v-for="(item, index) in techniques" :key="index">
            <div class="row-wrapper p-2">
              <label class="mt-2" for="title">Title </label>
              <el-select
                v-model="item.techniqueId"
                class="m-2"
                placeholder="Please select one"
                size="large"
                filterable
                :filter-method="findOnServer"
              >
                <el-option
                  v-for="item in techniquesList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <div
                class="delete-row"
                @click="
                  () => {
                    this.techniques.splice(index, 1);
                  }
                "
              >
                <svg
                  class="svg-inline--fa fa-trash-alt fa-w-14"
                  data-v-b4ae8fbc=""
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="trash-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <el-button
          class="button_style_1 mt-2"
          @click="
            () => {
              this.techniques.push({
                id: this.techniquesCounter++,
                techniqueId: 'Please select',
              });
            }
          "
        >
          Add New Row
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ElButton, ElSelect, ElOption } from "element-plus";
import { fetchConfig } from "../../helpers/fetchConfig";
export default {
  props: {
    postType: {
      type: String,
    },
  },
  components: {
    ElButton,
    ElSelect,
    ElOption,
  },
  data() {
    return {
      techniques: [],
      techniquesCounter: 0,
      techniquesList: [],
    };
  },
  watch: {
    techniquesList: function (newVal, oldVal) {
      if (this.$route.query.mode !== "edit") {
        console.log(newVal, oldVal);
        // let val = this.artistListRaw.find((x) => x.id === newVal);
        // this.$store.dispatch("bindValueIndicators", {
        //   type: "artist",
        //   data: {
        //     title: val.value_indicator.name,
        //     textList: [
        //       {
        //         id: 0,
        //         text: val.value_indicator.content,
        //       },
        //     ],
        //   },
        // });
      }
    },
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$store.dispatch("updateValue", {
          type: this.postType,
          data: {
            techniques: this.techniques,
          },
        });
      }
      if (mutation.type === "COLLECT_FROM_CF") {
        this.techniques = state.cf.cf.artworks.techniques;
      }
    });
  },
  mounted() {
    const loader = this.$loading.show();
    fetchConfig("techniques", this.$cookies.get("bearerToken")).then(
      ({ data: { data } }) => {
        if (data.length > 0) {
          data.map((technique) => {
            this.techniquesList.push({
              id: technique.id,
              label: technique.name,
              value: technique.id,
            });
          });
        }
      }
    );
    loader.hide();
  },
};
</script>

<style lang="scss" scoped>
.delete-row > svg {
  color: white;
  fill: white;
}
.required-field {
  color: red;
  font-weight: bold;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
</style>